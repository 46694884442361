import React from 'react';
import { RichTextEditorComponent, Toolbar,
Link, Image, Inject, Count, HtmlEditor, QuickToolbar, Table,
FileManager } from '@syncfusion/ej2-react-richtexteditor'
import { Header } from '../components';
import { EditorData } from '../data/dummy';

const Editor = () => {
  return (
    <div className='m-2 md:m-10 mt-24 p-2 md:p-10 bg-white rounded-3xl'>
    <Header category="App" title="Bodhi's HTML Editor of Fun"/>
      <RichTextEditorComponent>
        <EditorData />
          <Inject services={[Toolbar, Link, Image, Count, HtmlEditor,
                            FileManager, QuickToolbar, Table]} />
      </RichTextEditorComponent>
    </div>
  )
}

export default Editor