import React from 'react'

const Button = ({ bgColor, color, size, text, borderRadius}) => {
  return (
    <button
      type='button'
      style={{ backgroundColor: bgColor, color, text,
      borderRadius }}
      className={`text-${size} p-3
      hover:drop-shadow-xl`}
    >
      Learn More
    </button>
  )
}

export default Button