import React from 'react'
import { GoInfo } from 'react-icons/go';
import { Stacked,  Button } from '../components'
import { earningData,} from '../data/dummy';
import { useStateContext } from '../contexts/ContextProvider';

const Infohub = () => {
  const { currentColor } = useStateContext();
  return (
    
    <div className='mt-12'>
      <div className=" flex flex-wrap
      lg:flex-nowrap justify-center">
          <div className='bg:white dark:text-gray-200
          dark:bg-secondary-dark-bg h-44 rounded-xl
          w-full pt-9 m-3 bg-hero-pattern dark:bg-globe-pattern
          bg-no-repeat bg-cover bg-center
          hover: drop-shadow-lg hover: '>
            <div className='flex 
            justify-between items-center'>
              <div>
                <p className=
                'font-bold text-gray-500 pl-1'>
                  Average Attacks Everyday</p>
              
                <p className='text-2xl pl-1 dark:text-gray-100 '>
                  3.8 Million</p>
                
              </div>
            </div>
              <div className='mt-6 pl-1'>
                <Button 
                color="white"
                bgColor={currentColor}
                borderRadius="10px"
                text="Download"
                size="md"
                />
              </div>
          </div>
       </div>
            <div className='flex m-3 flex-wrap
            justify-center gap-1 items-center'>
              {earningData.map((item) => (
              <div
                key={item.title} 
                className='bg-white dark:text-gray-200
                dark:bg-secondary-dark-bg md:w-56
                p-4 pt-9 rounded-2xl hover:drop-shadow-xl'
              >
                  <button type='button'
                  style={{ color: item.iconColor,
                  backgroundColor: item.iconBg }}
                  className='text-2xl opacity-0.9
                  rounded-full p-4 hover:drop-shadow-xl'>
                      {item.icon}
                  </button>
                      <p className='mt-3'>
                          <span className='text-lg
                          font-semibold'>
                              {item.amount}
                          </span>
                         
                      </p>
                      <p className='text-sm text-gray-400
                      mt-1'>
                        {item.title}
                      </p>
              </div>
              ))}
            </div>

            <div className='flex gap-1 flex-wrap 
            justify-center'>
                <div className='bg-white dark:text-gray-20
                dark:bg-secondary-dark-bg m-3 p-4 rounded-2xl md:w-780'>
                    <div className='flex
                    justify-between'>
                      <p className='semi-bold text-xl dark:text-white'>
                        Cybersecurity Facts
                      </p>

                    <div className='flex items-center gap-4'>
                      <p>
                        <span className='flex items-center gap-2
                        text-gray-600 hover:drop-shadow-xl dark:text-white'>
                          <GoInfo />
                        </span>
                        <span className='dark:text-gray-200'>
                          Common Attacks
                      </span>
                      </p>
                    </div>

                    </div>
                    <div className='mt-10 flex gap-10 flex-wrap
                    justify-between'>
                      <div className='border-r-1
                      border-color m-4 pr-10'>
                          <div>
                            <p>
                            <span 
                            className='text-3xl font-semibold cursor-pointer dark:text-white'>
                              3.8 Million
                            </span>
                            </p>
                            <p className='text-gray-500 mt-1 dark:text-gray-200'>
                              Cyber Attacks Per Day
                            </p>
                          </div>
                          <div className='mt-8'>
                            <p>
                            <span 
                            className='text-3xl font-semibold dark:text-white'>
                              ~250 Days
                            </span>
                            </p>
                            <p className='text-gray-500 mt-1 dark:text-gray-200'>
                              Average Time to Identify Cyber Breach
                            </p>
                          </div>

                          <div className='mt-5 bg-hacker-pattern
                          bg-cover h-44 
                          w-full bg-no-repeat bg-center' />
                         
                          <div>
                              <div className='mt-10'>
                                <Button 
                                  color="white"
                                  bgColor={currentColor}
                                  text="Explore More"
                                  borderRadius="10px"
                                />
                              </div>
                          </div>
                         
                      </div>
                      <div className='md:pt-4'>
                            <Stacked width="320px" height="360px"/>
                         </div>
                    </div>
                  </div>
            </div>    
    </div>
  )
}

export default Infohub