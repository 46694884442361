import React from 'react'
import { AccumulationChartComponent, AccumulationSeriesCollectionDirective, AccumulationSeriesDirective, Inject, AccumulationLegend, PieSeries, AccumulationTooltip, AccumulationDataLabel } from '@syncfusion/ej2-react-charts';
import { pieChartData } from '../../data/dummy';
import { Browser } from '@syncfusion/ej2/base';
import { useStateContext } from '../../contexts/ContextProvider';


const Pie = ({ width, height }) => {
  const { currentMode } = useStateContext();
  return (
    <AccumulationChartComponent
    width={width}
    height={height}
    id='charts'
    chartArea={{border: {width: 0}}}
    tooltip={{ enable:true}}
    background={currentMode === 'Dark' ? '#33373E' : '#fff'}
    >
      <Inject services={[AccumulationLegend, PieSeries, AccumulationDataLabel, AccumulationTooltip]}/>
        <AccumulationSeriesCollectionDirective>
          <AccumulationSeriesDirective dataSource={pieChartData} name='Most Attacked Server Ports' xName='x' yName='y' explode={true} explodeOffset='10%' explodeIndex={0} startAngle={Browser.isDevice ? 55 : 35} dataLabel={{ visible: true, position: 'Outside', name: 'text', font: { fontWeight: '600' }, connectorStyle: { length: '20px', type: 'Curve' } }} radius={Browser.isDevice ? '40%' : '70%'}/>
        </AccumulationSeriesCollectionDirective>
    </AccumulationChartComponent>
  )
}

export default Pie