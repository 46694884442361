import React from 'react'
import { GridComponent, ColumnsDirective, ColumnDirective, Page, Search, Inject } from '@syncfusion/ej2-react-grids'
import { compactData, employeesGrid} from '../data/dummy'
import { Header } from '../components'
import { Toolbar } from '@syncfusion/ej2-react-grids/src'



const CyberPatriots = () => {
  return (
    <div className='m-2 md:m-10 md:p-10 bg-white rounded-3xl'>
    <Header category="Page" title="Compact Members" />
    <GridComponent
      dataSource={compactData}
      allowPaging
      allowSorting
      toolbar={['Search']}
      width="auto"
    >
      <ColumnsDirective>  
        {employeesGrid.map((item, index) => (
          <ColumnDirective key={index} {...item}/>
        ))}
      </ColumnsDirective>
      <Inject services={[Page, Search, Toolbar]} />
    </GridComponent>
    </div>
  )
}

export default CyberPatriots