import React from 'react'
import { portDescriptions } from '../data/dummy';
import { Pie } from '../components';
import { Button } from '../components';
import { useStateContext } from '../contexts/ContextProvider';

const Ports = () => {
  const { currentColor } = useStateContext();
  return (
    <div className='mt-12'>
      <div className=" flex flex-wrap
      lg:flex-nowrap justify-center">
          <div className='bg:white dark:text-gray-200
          dark:bg-secondary-dark-bg h-44 rounded-xl
          w-full pt-9 m-3 bg-port-pattern dark:bg-scale-pattern
          bg-no-repeat bg-cover bg-center
          hover: drop-shadow-lg'>
            <div className='flex 
              justify-between items-center'>
            </div>
          <div>
            <p className=
                'font-bold text-gray-400 text-center pr-2'>
                  Cybersecurity Essentials</p>
              
                <p className='text-2xl pr-2 text-center dark:text-gray-200'>
                  Commonly Attacked Ports</p>
          </div>
          <div className='mt-6 pr-2 text-center '>
          <Button
                color="white"
                bgColor={currentColor}
                borderRadius="10px"
                text="Download"
                size="md"
                />
          </div>
            </div>
     </div>
      
        
          <div className='flex m-3 flex-wrap
          justify-center gap-1 items-center'>
          {portDescriptions.map((item) => (
          <div
          key={item.title} 
          className='bg-white dark:text-gray-200
         dark:bg-secondary-dark-bg md:w-56
          p-4 pt-9 rounded-2xl hover:drop-shadow-xl'
          >
      <button type='button'
      style={{ color: item.iconColor,
      backgroundColor: item.iconBg }}
      className='text-2xl opacity-0.9
      rounded-full p-4 hover:drop-shadow-xl'>
          {item.icon}
      </button>
          <p className='mt-3'>
              <span className='text-lg
              font-semibold'>
                  {item.amount}
              </span>
             
          </p>
          <p className='text-sm text-gray-400
          mt-1'>
            {item.title}
          </p>
  </div>
  ))}
  </div>

        <div className=' flex w-full items-center justify-center'>
          <Pie width="400px" height="400px"/>
        </div>

</div>
  )
}

export default Ports