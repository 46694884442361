import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { FiSettings } from 'react-icons/fi';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { Navbar, Sidebar, ThemeSettings } from './components';
import { Ports, CyberPatriots, Compacts, Calendar, Pyramid,
 Kanban, Area, Bar, Pie, ColorPicker, ColorMapping, Editor, Line, Infohub}
 from './pages';

import { useStateContext } from './contexts/ContextProvider';

import './App.css'

const App = () => {
    const { activeMenu, currentMode, themeSettings, setThemeSettings, currentColor } = useStateContext();
  return (
    <div className={currentMode === 'Dark' ? 'dark' : ''}>
        <BrowserRouter>
            <div className='flex relative dark:bg-main-dark-bg'>
                <div className='fixed right-4 bottom-4' 
                style={{ zIndex: '1000' }}>
                    <TooltipComponent content="Settings" position='Top'>
                        <button type='button'
                        className='text-3xl p-3
                        hover:drop-shadow-xl
                        hover:bg-light-gray text-white'
                        onClick={() => setThemeSettings(true)}
                        style={{ background: currentColor, 
                        borderRadius: '50%' }}>
                        
                            <FiSettings />
                        </button>
                    </TooltipComponent>
                </div>
                {activeMenu ? (
                    <div className='w-72 fixed
                    sidebar
                    dark:bg-secondary-dark-bg bg-white'>
                        <Sidebar />
                    </div>
                ) : (
                    <div className='w-0 dark:bg-secondary-dark-bg'>
                        <Sidebar />
                    </div>
                )}
                <div 
                    className={ `dark:bg-bamboo-pattern dark:bg-[#20232A] bg-light-bam-pattern bg-[#FAFBFB] min-h-screen w-full 
                    ${activeMenu ? 'md:ml-72' : 'flex-2'}`
                    
                }>
                    <div className='fixed md:static
                    bg-main-bg dark:bg-main-dark-bg navbar w-full dark:drop-shadow-xl'>
                        <Navbar />
                    </div>
                   

                <div>
                    
                {themeSettings && <ThemeSettings />}

                    <Routes>
                        {/*Dashboard*/}
                        <Route path="/Infohub" element={<Infohub/>} />
                

                        {/*Pages*/}
                        <Route path='/Ports' element={<Ports />} />
                        <Route path='/CyberPatriots' element={<CyberPatriots />} />
                        <Route path='/Compacts' element={<Compacts />} />

                        {/*Apps*/}
                        <Route path='/kanban' element={<Kanban/>} />
                        <Route path='/editor' element={<Editor/>} />
                        <Route path='/calendar' element={<Calendar/>} />
                        <Route path='/color-picker' element={<ColorPicker/>} />

                        {/*Charts */}
                        <Route path='/line' element={<Line/>} />
                        <Route path='/area' element={<Area/>} />
                        <Route path='/bar' element={<Bar/>} />
                      <Route path='/pie' element={<Pie/>} />
                        <Route path='/color-mapping' element={<ColorMapping/>} />
                        <Route path='/pyramid' element={<Pyramid/>} />
                   </Routes>
                </div>
                </div>
            </div>

        </BrowserRouter>
    </div>
  )
}

export default App